import { useContext } from 'react'

import { UltraLipstickContext } from './index'

export const useUltraLipstickSelection = () => {
  const context = useContext(UltraLipstickContext)

  if (context === undefined) {
    throw new Error('Region context provider missing from React tree')
  }

  return context
}
