import React, { useRef } from 'react'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import { Box } from '@vtex/store-ui'
import EventPageView from 'src/components/common/EventPageView'
import PageBanner from 'src/components/common/PageBanner'
import AboveContent from 'src/components/batons-ultras/Above'
import type { BatomUltraQueryQuery } from 'src/pages/batons-ultras/__generated__/BatomUltraQuery.graphql'

type Props = PageProps<BatomUltraQueryQuery>

const Above: FC<Props> = ({ data }) => {
  const introSection = useRef(null)

  const handleScroll = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: introSection?.current.offsetTop - 130,
    })
  }

  return (
    <Box>
      <EventPageView
        type="OTHER"
        title={data.cmsInstitutionalPage.seo.siteMetadataWithSlug.title}
      />
      <Box sx={{ cursor: 'pointer' }} onClick={handleScroll}>
        <PageBanner
          {...data.cmsInstitutionalPage?.sections[0].props}
          sx={{ paddingBottom: '0' }}
          heightInline={['486px', 'auto']}
          heightBanner={{ height: 550, width: 1920 }}
          plLandingPage
        />
      </Box>
      <Box ref={introSection}>
        <AboveContent data={data} />
      </Box>
    </Box>
  )
}

export default Above
