import React from 'react'
import { Box, Center, Shelf, ShelfContainer, Spinner } from '@vtex/store-ui'
import type { FC, ReactNode } from 'react'
import EventImpressions from 'src/components/common/EventImpressions'
import type { ProductPageType } from 'src/views/product/components/typings/product'

interface ShelfProductsProps {
  searchParams?: {
    collection: string
    from: number
    to: number
    hideUnavailableItems: boolean
    orderBy: string
  }
  pageSizes?: number[]
  products?: ProductPageType[]
  isStaticShelf?: boolean
  showArrows?: boolean
  ProductSummary: ReactNode
  shelfName?: string
}

const ShelfProducts: FC<ShelfProductsProps> = (props) => {
  return (
    <>
      {!props.products ? (
        <Box sx={{ height: ['200px', '300px'] }}>
          <Center>
            <Spinner />
          </Center>
        </Box>
      ) : (
        <ShelfContainer>
          {props.shelfName === 'UltraNude' && (
            <Shelf
              {...props}
              products={props.products}
              pageSizes={props.pageSizes ?? [1, 3, 4]}
            />
          )}
          {props.shelfName === 'UltraMalva' && (
            <Shelf
              {...props}
              products={props.products}
              pageSizes={props.pageSizes ?? [1, 3, 4]}
            />
          )}
          {props.shelfName === 'UltraOusados' && (
            <Shelf
              {...props}
              products={props.products}
              pageSizes={props.pageSizes ?? [1, 3, 4]}
            />
          )}
          {props.shelfName === 'UltraRosa' && (
            <Shelf
              {...props}
              products={props.products}
              pageSizes={props.pageSizes ?? [1, 3, 4]}
            />
          )}
          {props.shelfName === 'UltraVermelhos' && (
            <Shelf
              {...props}
              products={props.products}
              pageSizes={props.pageSizes ?? [1, 3, 4]}
            />
          )}
          <EventImpressions products={props.products} type="HOME" />
        </ShelfContainer>
      )}
    </>
  )
}

export default ShelfProducts
