import React from 'react'
import type { FC } from 'react'
import { Helmet } from 'react-helmet'
import type { PageProps } from 'gatsby'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import { useSiteLinksSearchBoxJsonLd } from 'src/views/home/Seo/useSiteLinksSearchBoxJsonLd'
import { getCookie } from 'src/utils/cookies'
import type { BatomUltraPageQueryQuery } from 'src/pages/batons-ultra/__generated__/BatomUltraPageQuery.graphql'

type Props = PageProps<BatomUltraPageQueryQuery>

const Seo: FC<Props> = (props) => {
  const siteMetadata = props.data.cmsInstitutionalPage.seo.siteMetadataWithSlug

  const canonicalPathname = `https://www.avon.com.br/${siteMetadata.slug}`
    .replace('.br//', '.br/')
    .replace('.br///', '.br/')

  const siteLinksSearchBox = useSiteLinksSearchBoxJsonLd(props)

  const repId = getCookie('selectedRepresentantId')

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalPathname} />
      </Helmet>
      <GatsbySeo
        title={siteMetadata?.title || 'Linha de Batons Ultra | Avon'}
        description={siteMetadata?.description || 'Linha de Batons Ultra Avon'}
        titleTemplate={siteMetadata?.title || '%s | Avon'}
        noindex={!!repId}
      />
      <JsonLd json={siteLinksSearchBox} defer />
    </>
  )
}

export default Seo
