export const banners = [
  {
    id: 1,
    image: 9,
    label: 'Vinho Fino',
  },
  {
    id: 2,
    image: 10,
    label: 'Vermelho Quente',
  },
  {
    id: 3,
    image: 11,
    label: 'Vermelho Maçã',
  },
]
