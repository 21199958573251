import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Box, Flex, Image, Text, Button } from '@vtex/store-ui'
import { useUltraLipstickSelection } from 'src/contexts/UltraLipstickProvider/useLipstickSelection'
import { useQuery } from '@vtex/gatsby-theme-store'
import Shelf from 'src/components/product/ProductSingleSlider'
import ProductSummary from 'src/components/product/SingleProductSummary'
import { Hidden } from 'src/components/ui/Hidden'
import { ArrowLeft, DragHandIcon } from 'src/components/icons'

import { productsBySkuIdQueryAndProductsBySkuIdQuery } from '../__generated__/productsBySkuIdQueryAndProductsBySkuIdQuery.graphql'
import type {
  productsBySkuIdQueryAndProductsBySkuIdQueryQuery,
  productsBySkuIdQueryAndProductsBySkuIdQueryQueryVariables,
} from '../__generated__/productsBySkuIdQueryAndProductsBySkuIdQuery.graphql'
import { banners } from './banners'
import { ultraPinkCards } from '../../mock'
import styles from '../../styles.json'

const UltraRosaSection = ({ data: props }) => {
  const [current, setCurrent] = useState<number>(0)
  const { setIsItemSelected, ultraRosaItemSelected, setUltraRosaItemSelected } =
    useUltraLipstickSelection()

  const { data } = useQuery<
    productsBySkuIdQueryAndProductsBySkuIdQueryQuery,
    productsBySkuIdQueryAndProductsBySkuIdQueryQueryVariables
  >({
    ...productsBySkuIdQueryAndProductsBySkuIdQuery,
    variables: { id: ultraRosaItemSelected || 180906029 },
    revalidateOnMount: true,
  })

  const prevSlide = () => {
    setCurrent((state) => (state === 0 ? banners.length - 1 : state - 1))
  }

  const nextSlide = () => {
    setCurrent((state) => (state === banners.length - 1 ? 0 : state + 1))
  }

  return (
    <Box
      sx={{
        width: '100%',
        padding: ['1.875rem 0', '2.375rem 0'],
        background: ['#F8F6F6', '#F2F2F2'],
      }}
    >
      <Hidden device="mobile">
        <Flex sx={styles.wrapper}>
          <Box sx={styles.imageCarousel}>
            <Box sx={styles.imageCarousel.arrowsUltraRosa}>
              <Button
                value={current + 1 === 1 ? 'tab4' : `tab${current}`}
                data-input="prev"
                role="button"
                onClick={prevSlide}
              >
                <ArrowLeft />
              </Button>
              <Button
                value={current + 1 === 4 ? 'tab1' : `tab${current + 2}`}
                data-input="next"
                role="button"
                onClick={nextSlide}
              >
                <ArrowLeft />
              </Button>
            </Box>
            <Flex sx={styles.imageCarousel.cardModels.wrapper}>
              {banners.map((banner, index) => (
                <Box
                  key={index}
                  sx={index === current ? styles.show : styles.hide}
                >
                  <Box sx={styles.imageCarousel.cardModels.item}>
                    <Box>
                      <Image
                        src={
                          props.cmsInstitutionalPage.sections[banner.image]
                            .props.desktop.srcSet
                        }
                        title={banner.label}
                        alt={banner.label}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Flex>
            <Box sx={styles.imageCarousel.dots}>
              {banners.map((_, index: number) => (
                <Box
                  key={index}
                  sx={
                    current === index
                      ? styles.imageCarousel.dots.itemActiveUltraRosa
                      : styles.imageCarousel.dots.itemUltraRosa
                  }
                />
              ))}
            </Box>
          </Box>
          <Box sx={styles.cardContainer}>
            <Box sx={styles.cardContainer.texts}>
              <Text as="h1">
                Ultra animada combina com{' '}
                <Text as="strong" sx={{ color: '#EC4591' }}>
                  ultra rosa.
                </Text>
              </Text>
              <Text as="p">
                A leveza do rosa sintoniza seu batom com seu bom humor e traz
                energia para o look.{' '}
                <Link to="/batons-ultras/ultramatte">Ver todos</Link>
              </Text>
            </Box>
            <Box sx={styles.cardContainer.options}>
              {ultraPinkCards && (
                <Flex sx={styles.cardContainer.options.content}>
                  {ultraPinkCards.map((product, index) => (
                    <Button
                      key={index}
                      className={
                        product.skuId === ultraRosaItemSelected
                          ? 'isActive'
                          : ''
                      }
                      onClick={() => {
                        setIsItemSelected(true)
                        setUltraRosaItemSelected(product.skuId)
                      }}
                    >
                      <Box sx={styles.cardContainer.options.productImage}>
                        <Image
                          src={product.thumb}
                          alt={product.name}
                          title={product.name}
                        />
                      </Box>
                    </Button>
                  ))}
                </Flex>
              )}
            </Box>
          </Box>
          <Box sx={styles.shelfContainer}>
            {data?.vtex.productsByIdentifier.length && (
              <>
                <Text as="p" sx={styles.shelfContainer.choosed}>
                  Esse é o seu acabamento escolhido:
                </Text>
                <Box sx={styles.shelfContainer.products}>
                  <Shelf
                    products={data?.vtex.productsByIdentifier}
                    ProductSummary={ProductSummary}
                    shelfName="UltraRosa"
                    isStaticShelf
                  />
                </Box>
                <Text as="p" sx={styles.shelfContainer.description}>
                  Clique no produto para ver a cor que você escolheu e outras
                  opções de bocas!
                </Text>
              </>
            )}
          </Box>
        </Flex>
      </Hidden>
      <Hidden device="desktop">
        <Flex sx={styles.wrapper}>
          <Box sx={styles.cardContainer}>
            <Box sx={styles.cardContainer.texts}>
              <Text as="h1">
                Ultra animada combina com{' '}
                <Text as="strong" sx={{ color: '#EC4591' }}>
                  ultra rosa.
                </Text>
              </Text>
              <Text as="p">
                A leveza do rosa sintoniza seu batom com seu bom humor e traz
                energia para o look.{' '}
                <Link to="/batons-ultras/ultramatte">Ver todos</Link>
              </Text>
            </Box>
            <Box sx={styles.imageCarousel}>
              <Box sx={styles.imageCarousel.arrowsUltraRosa}>
                <Button
                  value={current + 1 === 1 ? 'tab4' : `tab${current}`}
                  data-input="prev"
                  role="button"
                  onClick={prevSlide}
                >
                  <ArrowLeft />
                </Button>
                <Button
                  value={current + 1 === 4 ? 'tab1' : `tab${current + 2}`}
                  data-input="next"
                  role="button"
                  onClick={nextSlide}
                >
                  <ArrowLeft />
                </Button>
              </Box>
              <Flex sx={styles.imageCarousel.cardModels.wrapper}>
                {banners.map((banner, index) => (
                  <Box
                    key={index}
                    sx={index === current ? styles.show : styles.hide}
                  >
                    <Box sx={styles.imageCarousel.cardModels.item}>
                      <Box>
                        <Image
                          src={
                            props.cmsInstitutionalPage.sections[banner.image]
                              .props.mobile.srcSet
                          }
                          title={banner.label}
                          alt={banner.label}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Flex>
              <Box sx={styles.imageCarousel.dots}>
                {banners.map((_, index: number) => (
                  <Box
                    key={index}
                    sx={
                      current === index
                        ? styles.imageCarousel.dots.itemActiveUltraRosa
                        : styles.imageCarousel.dots.itemUltraRosa
                    }
                  />
                ))}
              </Box>
            </Box>
            <Box sx={styles.cardContainer.options}>
              <Flex sx={styles.cardContainer.options.instructions}>
                <DragHandIcon />
                <Text as="span">Escolha sua cor e acabamento</Text>
              </Flex>
              {ultraPinkCards && (
                <Flex sx={styles.cardContainer.options.content}>
                  {ultraPinkCards.map((product, index) => (
                    <Button
                      key={index}
                      className={
                        product.skuId === ultraRosaItemSelected
                          ? 'isActive'
                          : ''
                      }
                      onClick={() => {
                        setIsItemSelected(true)
                        setUltraRosaItemSelected(product.skuId)
                      }}
                    >
                      <Box sx={styles.cardContainer.options.productImage}>
                        <Image
                          src={product.thumb}
                          alt={product.name}
                          title={product.name}
                        />
                      </Box>
                    </Button>
                  ))}
                </Flex>
              )}
            </Box>
            <Box sx={styles.shelfContainer}>
              {data?.vtex.productsByIdentifier.length && (
                <>
                  <Text as="p" sx={styles.shelfContainer.choosed}>
                    Esse é o seu acabamento escolhido:
                  </Text>
                  <Box sx={styles.shelfContainer.products}>
                    <Shelf
                      products={data?.vtex.productsByIdentifier}
                      ProductSummary={ProductSummary}
                      shelfName="UltraRosa"
                      isStaticShelf
                    />
                  </Box>
                  <Text as="p" sx={styles.shelfContainer.description}>
                    Clique no produto para ver a cor que você escolheu e outras
                    opções de bocas!
                  </Text>
                </>
              )}
            </Box>
          </Box>
        </Flex>
      </Hidden>
    </Box>
  )
}

export default UltraRosaSection
