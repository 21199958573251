export const firstUltraNudeCards = [
  {
    name: 'Batom Ultracremoso FPS 15 3,6g - Nude Caramelo',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/nude-caramelo.png',
    skuId: '194491727',
  },
  {
    name: 'Batom Ultramatte 3,6g - Nude Terracota',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/nude-terracota.png',
    skuId: '194489367',
  },
  {
    name: 'Batom Líquido Lip Paint Ultra Color - Atitude Nude 7ml',
    type: 'Semimatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/atitude-nude.png',
    skuId: '194491326',
  },
  {
    name: 'Gloss Labial Avon Ultra Color 7ml - Marrom Must Have',
    type: 'Brilhante',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/marrom-must-have.png',
    skuId: '194488795',
  },
]

export const secondUltraNudeCards = [
  {
    name: 'Batom Ultramatte 3,6g - Nude Bronze',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/nude-bronze.png',
    skuId: '194489364',
  },
  {
    name: 'Batom Ultramatte 3,6g - Nude Rose',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/nude-rose.png',
    skuId: '194489366',
  },
  {
    name: 'Batom Ultracremoso FPS 15 3,6g - Nude Cappuccino',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/nude-cappuccino.png',
    skuId: '194491731',
  },
  {
    name: 'Batom Líquido Lip Paint Ultra Color - Nude Delicado 7ml',
    type: 'Semimatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/nude-delicado.png',
    skuId: '194491325',
  },
  {
    name: 'Gloss Labial Avon Ultra Color 7ml - Nude Glow',
    type: 'Brilhante',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/nude-glow.png',
    skuId: '194488794',
  },
  {
    name: 'Batom Ultramatte 3,6g - Nude Terracota',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/nude-terracota.png',
    skuId: '194489367',
  },
]

export const thirdUltraNudeCards = [
  {
    name: 'Batom Ultramatte Nude Chocolate 3,6g',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/nude-chocolate.png',
    skuId: '194491703',
  },
  {
    name: 'Batom Líquido Lip Paint Ultra Color - Atitude Nude 7ml',
    type: 'Semimatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/atitude-nude.png',
    skuId: '194487535',
  },
  {
    name: 'Batom Ultramatte 3,6g - Nude Marsala',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/nude-marsala.png',
    skuId: '194489365',
  },
  {
    name: 'Batom Ultramatte 3,6g - Nude Marrom',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/nude-marrom.png',
    skuId: '194489363',
  },
  {
    name: 'Batom Ultracremoso FPS 15 3,6 g - Marrom Puro',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/marrom-puro.png',
    skuId: '194488603',
  },
  {
    name: 'Gloss Labial Ultra Color - Nude Rubi 7ml',
    type: 'Brilhante',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/nude-rubi.png',
    skuId: '194491705',
  },
]

export const ultraMalvaCards = [
  {
    name: 'Batom Avon Ultramatte 3,6g - Malva',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/malva.png',
    skuId: '180906042',
  },
  {
    name: 'Batom Avon Ultramatte 3,6g - Vinho',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/vinho.png',
    skuId: '180906038',
  },
  {
    name: 'Batom Ultracremoso FPS 15 3,6g - Malva Oriente',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/malva-oriente.png',
    skuId: '194488456',
  },
  {
    name: 'Batom Ultracremoso FPS 15 3,6g - Canela',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/canela.png',
    skuId: '194488127',
  },
  {
    name: 'Batom Avon Ultramatte 3,6g - Malva Misterioso',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/malva-misterioso.png',
    skuId: '180905967',
  },
]

export const ultraOusadosCards = [
  {
    name: 'Batom Ultramatte Roxo Uva 3,6g',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/roxo-uva.png',
    skuId: '194491704',
  },
  {
    name: 'Batom Avon Ultramatte 3,6g - Orquídea',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/orquidea.png',
    skuId: '180906050',
  },
  {
    name: 'Batom Ultracremoso FPS 15 3,6g - Vinho Marsala',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/vinho-marsala.png',
    skuId: '194491730',
  },
  {
    name: 'Batom Líquido Lip Paint Ultra Color - Marsala Profundo 7ml',
    type: 'Semimatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/marsala-profundo.png',
    skuId: '194491330',
  },
  {
    name: 'Gloss Labial Avon Ultra Color 7ml - Holo Shine',
    type: 'Brilhante',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/brilho-holo-shine.png',
    skuId: '194488798',
  },
]

export const ultraPinkCards = [
  {
    name: 'Batom Avon Ultramatte 3,6g - Pink',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/pink.png',
    skuId: '180906029',
  },
  {
    name: 'Batom Avon Ultramatte 3,6g - Cereja',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/cereja.png',
    skuId: '180906063',
  },
  {
    name: 'Batom Avon Ultramatte 3,6g - Amora',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/amora.png',
    skuId: '180906054',
  },
  {
    name: 'Batom Avon Ultramatte 3,6g - Goiaba',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/goiaba.png',
    skuId: '180906000',
  },
  {
    name: 'Gloss Labial Avon Ultra Color 7ml - Rosa Wow',
    type: 'Brilhante',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/rosa-wow.png',
    skuId: '194488797',
  },
  {
    name: 'Batom Ultracremoso FPS 15 3,6g - Rosa Queimado',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/rosa-queimado.png',
    skuId: '194488129',
  },
  {
    name: 'Batom Ultracremoso FPS 15 3,6g - Rose Nude',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/rose-nude.png',
    skuId: '194491728',
  },
  {
    name: 'Batom Ultracremoso FPS 15 3,6g - Malva Floral',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/malva-floral.png',
    skuId: '194488273',
  },
  {
    name: 'Batom Líquido Lip Paint Ultra Color - Rose Singular 7ml',
    type: 'Semimatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/rose-singular.png',
    skuId: '194491327',
  },
  {
    name: 'Batom Líquido Lip Paint Ultra Color - Doce Rosa 7ml',
    type: 'Semimatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/doce-rosa.png',
    skuId: '194491331',
  },
  {
    name: 'Batom Ultracremoso FPS 15 3,6g - Rose Malva',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/rose-malva.png',
    skuId: '194491726',
  },
  {
    name: 'Batom Ultracremoso FPS 15 3,6g - Rosa Intenso',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/rosa-intenso.png',
    skuId: '194491732',
  },
]

export const ultraVermelhosCards = [
  {
    name: 'Batom Avon Ultramatte 3,6g - Vermelho Tendência',
    type: 'Ultramatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/vermelho-tendencia.png',
    skuId: '180905963',
  },
  {
    name: 'Batom Ultracremoso FPS 15 3,6 g -  Vermelho Puro',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/vermelho-puro.png',
    skuId: '194488600',
  },
  {
    name: 'Batom Ultracremoso FPS 15 3,6 g - Vinho Fino',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/vinho-fino.png',
    skuId: '194488599',
  },
  {
    name: 'Batom Avon Ultramatte 3,6g - Cereja Malva',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/cereja-malva.png',
    skuId: '194488800',
  },
  {
    name: 'Batom Líquido Lip Paint Ultra Color - Vermelho Quente 7ml',
    type: 'Semimatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/vermelho-quente.png',
    skuId: '194491328',
  },
  {
    name: 'Batom Líquido Lip Paint Ultra Color - Framboesa Intenso 7ml',
    type: 'Semimatte',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/framboesa-intenso.png',
    skuId: '194491329',
  },
  {
    name: 'Batom Ultracremoso FPS 15 - 3,6g - Vermelho Maçã ',
    type: 'Cremoso',
    thumb: 'https://avongroup.vteximg.com.br/arquivos/vermelho-maca.png',
    skuId: '194491729',
  },
]
