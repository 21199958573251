export const banners = [
  {
    id: 1,
    image: 1,
    label: 'Nude Caramelo',
  },
  {
    id: 2,
    image: 2,
    label: 'Nude Bronze',
  },
  {
    id: 3,
    image: 3,
    label: 'Nude Chocolate',
  },
]
