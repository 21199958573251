import React, { lazy } from 'react'
import type { FC } from 'react'
import type { PageProps as GatsbyPageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { UltraLipstickProvider } from 'src/contexts/UltraLipstickProvider'
import Layout from 'src/components/Layout'
import { View } from 'src/components/ui/View'
import Seo from 'src/views/batons-ultras/Seo'
import AboveTheFold from 'src/views/batons-ultras/AboveTheFold'

import type { BatomUltraQueryQuery } from './__generated__/BatomUltraQuery.graphql'

const loader = () => import('src/views/batons-ultras/BelowTheFold')
const BelowTheFold = lazy(loader)

export type Props = GatsbyPageProps<BatomUltraQueryQuery>

const ViewComponents = {
  seo: Seo,
  above: AboveTheFold,
  below: {
    component: BelowTheFold,
    preloader: loader,
  },
}

const Page: FC<Props> = (props) => (
  <UltraLipstickProvider>
    <GoogleReCaptchaProvider reCaptchaKey="6Lf__bwUAAAAAAcD3DIJoQVhv8uMTkP08A32pHXW">
      <Layout>
        <View {...ViewComponents} data={props} />
      </Layout>
    </GoogleReCaptchaProvider>
  </UltraLipstickProvider>
)

export const query = graphql`
  query BatomUltraQuery {
    cmsInstitutionalPage(name: { eq: "Batom Ultra" }) {
      sections {
        name
        props: data
      }
      seo {
        siteMetadataWithSlug {
          description
          slug
          title
          titleTemplate
        }
      }
    }
  }
`

export default Page
