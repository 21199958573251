export const banners = [
  {
    id: 1,
    image: 6,
    label: 'Rosê Nude',
  },
  {
    id: 2,
    image: 7,
    label: 'Rosa Intenso',
  },
  {
    id: 3,
    image: 8,
    label: 'Rosê Malva',
  },
]
