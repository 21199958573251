import React, { useState } from 'react'
import { Box, Flex, Text } from '@vtex/store-ui'
import {
  UltramatteIcon,
  SemimatteIcon,
  CreamyIcon,
  BrillantIcon,
} from 'src/components/icons'

import styles from './styles.json'

const LegendIndicator = () => {
  const [isActive, setIsActive] = useState<boolean>(false)

  const handleToggle = () => {
    isActive ? setIsActive(false) : setIsActive(true)
  }

  return (
    <Box as="button" sx={styles.container} onClick={handleToggle}>
      <Text as="h4" sx={isActive ? styles.title : styles.hide}>
        Legenda
      </Text>
      <Flex sx={styles.wrapper}>
        <Box sx={styles.icon}>
          <UltramatteIcon />
        </Box>
        <Box sx={isActive ? styles.type : styles.hide}>
          <Text as="span">Ultramatte</Text>
        </Box>
      </Flex>
      <Flex sx={styles.wrapper}>
        <Box sx={styles.icon}>
          <CreamyIcon />
        </Box>
        <Box sx={isActive ? styles.type : styles.hide}>
          <Text as="span">Cremoso</Text>
        </Box>
      </Flex>
      <Flex sx={styles.wrapper}>
        <Box sx={styles.icon}>
          <SemimatteIcon />
        </Box>
        <Box sx={isActive ? styles.type : styles.hide}>
          <Text as="span">Semimatte</Text>
        </Box>
      </Flex>
      <Flex sx={styles.wrapper}>
        <Box sx={styles.icon}>
          <BrillantIcon />
        </Box>
        <Box sx={isActive ? styles.type : styles.hide}>
          <Text as="span">Brilhante</Text>
        </Box>
      </Flex>
    </Box>
  )
}

export default LegendIndicator
