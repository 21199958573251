import React from 'react'
import { graphql } from 'gatsby'
import { Box } from '@vtex/store-ui'

import UltraNudeSection from './UltraNudeSection'
import UltraMalvaSection from './UltraMalvaSection'
import UltraOusadosSection from './UltraOusadosSection'
import UltraRosaSection from './UltraRosaSection'
import UltraVermelhosSection from './UltraVermelhosSection'
import LegendIndicator from './LegendIndicator'

const AboveContent = ({ data }) => (
  <Box>
    <UltraNudeSection data={data} />
    <UltraMalvaSection data={data} />
    <UltraOusadosSection data={data} />
    <UltraRosaSection data={data} />
    <UltraVermelhosSection data={data} />
    <LegendIndicator />
  </Box>
)

export const query = graphql`
  query productsBySkuIdQueryAndProductsBySkuIdQuery($id: [ID!]!) {
    vtex {
      productsByIdentifier(field: sku, values: $id) {
        ...ProductSummary_product
      }
    }
    vtex {
      productsByIdentifier(field: sku, values: $id) {
        ...ProductSummary_product
      }
    }
  }
`

export default AboveContent
