import type { FC } from 'react'
import React, { useMemo, useState, createContext } from 'react'

export interface IContext {
  isItemSelected: boolean
  ultraNudeItemSelected: string
  ultraMalvaItemSelected: string
  ultraOusadosItemSelected: string
  ultraRosaItemSelected: string
  ultraVermelhosItemSelected: string
  setIsItemSelected: (value: boolean) => void
  setUltraNudeItemSelected: (value: string) => void
  setUltraMalvaItemSelected: (value: string) => void
  setUltraOusadosItemSelected: (value: string) => void
  setUltraRosaItemSelected: (value: string) => void
  setUltraVermelhosItemSeleted: (value: string) => void
}

export const UltraLipstickContext = createContext<IContext | undefined>(
  undefined
)

export const UltraLipstickProvider: FC = ({ children }) => {
  const [isItemSelected, setIsItemSelected] = useState<boolean>(false)
  const [ultraNudeItemSelected, setUltraNudeItemSelected] = useState<string>('')
  const [ultraMalvaItemSelected, setUltraMalvaItemSelected] =
    useState<string>('')

  const [ultraOusadosItemSelected, setUltraOusadosItemSelected] =
    useState<string>('')

  const [ultraRosaItemSelected, setUltraRosaItemSelected] = useState<string>('')
  const [ultraVermelhosItemSelected, setUltraVermelhosItemSeleted] =
    useState<string>('')

  const value = useMemo(
    () => ({
      isItemSelected,
      ultraNudeItemSelected,
      ultraMalvaItemSelected,
      ultraOusadosItemSelected,
      ultraRosaItemSelected,
      ultraVermelhosItemSelected,
      setIsItemSelected: (val: boolean) => {
        setIsItemSelected(val)
      },
      setUltraNudeItemSelected: (val: string) => {
        setUltraNudeItemSelected(val)
      },
      setUltraMalvaItemSelected: (val: string) => {
        setUltraMalvaItemSelected(val)
      },
      setUltraOusadosItemSelected: (val: string) => {
        setUltraOusadosItemSelected(val)
      },
      setUltraRosaItemSelected: (val: string) => {
        setUltraRosaItemSelected(val)
      },
      setUltraVermelhosItemSeleted: (val: string) => {
        setUltraVermelhosItemSeleted(val)
      },
    }),
    [
      isItemSelected,
      ultraNudeItemSelected,
      ultraMalvaItemSelected,
      ultraOusadosItemSelected,
      ultraRosaItemSelected,
      ultraVermelhosItemSelected,
    ]
  )

  return (
    <UltraLipstickContext.Provider value={value}>
      {children}
    </UltraLipstickContext.Provider>
  )
}
