import React from 'react'
import { Link } from 'gatsby'
import { Box, Flex, Image, Text, Button } from '@vtex/store-ui'
import { useUltraLipstickSelection } from 'src/contexts/UltraLipstickProvider/useLipstickSelection'
import { useQuery } from '@vtex/gatsby-theme-store'
import Shelf from 'src/components/product/ProductSingleSlider'
import ProductSummary from 'src/components/product/SingleProductSummary'
import { Hidden } from 'src/components/ui/Hidden'
import { DragHandIcon } from 'src/components/icons'

import { productsBySkuIdQueryAndProductsBySkuIdQuery } from '../__generated__/productsBySkuIdQueryAndProductsBySkuIdQuery.graphql'
import type {
  ProductsBySkuIdQueryAndProductsBySkuIdQueryQuery,
  ProductsBySkuIdQueryAndProductsBySkuIdQueryQueryVariables,
} from '../__generated__/productsBySkuIdQueryAndProductsBySkuIdQuery.graphql'
import { banners } from './banners'
import { ultraMalvaCards } from '../../mock'
import styles from '../../styles.json'

const UltraMalvaSection = ({ data: props }) => {
  const {
    setIsItemSelected,
    ultraMalvaItemSelected,
    setUltraMalvaItemSelected,
  } = useUltraLipstickSelection()

  const { data } = useQuery<
    ProductsBySkuIdQueryAndProductsBySkuIdQueryQuery,
    ProductsBySkuIdQueryAndProductsBySkuIdQueryQueryVariables
  >({
    ...productsBySkuIdQueryAndProductsBySkuIdQuery,
    variables: { id: ultraMalvaItemSelected || 180906042 },
    revalidateOnMount: true,
  })

  return (
    <Box
      sx={{
        width: '100%',
        padding: ['1.875rem 0', '2.375rem 0'],
        background: ['#F8F6F6', '#F2F2F2'],
      }}
    >
      <Hidden device="mobile">
        <Flex sx={styles.wrapper}>
          <Box sx={styles.imageCarousel}>
            <Flex sx={styles.imageCarousel.cardModels.wrapper}>
              {banners.map((banner, index) => (
                <Box key={index}>
                  <Box sx={styles.imageCarousel.cardModels.item}>
                    <Box>
                      <Image
                        src={
                          props.cmsInstitutionalPage.sections[banner.image]
                            .props.desktop.srcSet
                        }
                        title={banner.label}
                        alt={banner.label}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Flex>
          </Box>
          <Box sx={styles.cardContainer}>
            <Box sx={styles.cardContainer.texts}>
              <Text as="h1">
                Ultra romântica combina com{' '}
                <Text as="strong" sx={{ color: '#B05461' }}>
                  ultra malva.
                </Text>
              </Text>
              <Text as="p">
                Para os dias que você só quer um toque de cor na make. Efeito
                discreto de lábios coradinhos. Superchique!{' '}
                <Link to="/batons-ultras/lippaint">Ver todos</Link>
              </Text>
            </Box>
            <Box sx={styles.cardContainer.options}>
              {ultraMalvaCards && (
                <Flex sx={styles.cardContainer.options.content}>
                  {ultraMalvaCards.map((product, index) => (
                    <Button
                      key={index}
                      className={
                        product.skuId === ultraMalvaItemSelected
                          ? 'isActive'
                          : ''
                      }
                      onClick={() => {
                        setIsItemSelected(true)
                        setUltraMalvaItemSelected(product.skuId)
                      }}
                    >
                      <Box sx={styles.cardContainer.options.productImage}>
                        <Image
                          src={product.thumb}
                          alt={product.name}
                          title={product.name}
                        />
                      </Box>
                    </Button>
                  ))}
                </Flex>
              )}
            </Box>
          </Box>
          <Box sx={styles.shelfContainer}>
            {data?.vtex.productsByIdentifier.length && (
              <>
                <Text as="p" sx={styles.shelfContainer.choosed}>
                  Esse é o seu acabamento escolhido:
                </Text>
                <Box sx={styles.shelfContainer.products}>
                  <Shelf
                    products={data?.vtex.productsByIdentifier}
                    ProductSummary={ProductSummary}
                    shelfName="UltraMalva"
                    isStaticShelf
                  />
                </Box>
                <Text as="p" sx={styles.shelfContainer.description}>
                  Clique no produto para ver a cor que você escolheu e outras
                  opções de bocas!
                </Text>
              </>
            )}
          </Box>
        </Flex>
      </Hidden>
      <Hidden device="desktop">
        <Flex sx={styles.wrapper}>
          <Box sx={styles.cardContainer}>
            <Box sx={styles.cardContainer.texts}>
              <Text as="h1">
                Ultra romântica combina com{' '}
                <Text as="strong" sx={{ color: '#B05461' }}>
                  ultra malva.
                </Text>
              </Text>
              <Text as="p">
                Para os dias que você só quer um toque de cor na make. Efeito
                discreto de lábios coradinhos. Superchique!{' '}
                <Link to="/batons-ultras/lippaint">Ver todos</Link>
              </Text>
            </Box>
            <Box sx={styles.imageCarousel}>
              <Flex sx={styles.imageCarousel.cardModels.wrapper}>
                {banners.map((banner, index) => (
                  <Box key={index}>
                    <Box sx={styles.imageCarousel.cardModels.item}>
                      <Box>
                        <Image
                          src={
                            props.cmsInstitutionalPage.sections[banner.image]
                              .props.mobile.srcSet
                          }
                          title={banner.label}
                          alt={banner.label}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Flex>
            </Box>
            <Box sx={styles.cardContainer.options}>
              <Flex sx={styles.cardContainer.options.instructions}>
                <DragHandIcon />
                <Text as="span">Escolha sua cor e acabamento</Text>
              </Flex>
              {ultraMalvaCards && (
                <Flex sx={styles.cardContainer.options.content}>
                  {ultraMalvaCards.map((product, index) => (
                    <Button
                      key={index}
                      className={
                        product.skuId === ultraMalvaItemSelected
                          ? 'isActive'
                          : ''
                      }
                      onClick={() => {
                        setIsItemSelected(true)
                        setUltraMalvaItemSelected(product.skuId)
                      }}
                    >
                      <Box sx={styles.cardContainer.options.productImage}>
                        <Image
                          src={product.thumb}
                          alt={product.name}
                          title={product.name}
                        />
                      </Box>
                    </Button>
                  ))}
                </Flex>
              )}
            </Box>
            <Box sx={styles.shelfContainer}>
              {data?.vtex.productsByIdentifier.length && (
                <>
                  <Text as="p" sx={styles.shelfContainer.choosed}>
                    Esse é o seu acabamento escolhido:
                  </Text>
                  <Box sx={styles.shelfContainer.products}>
                    <Shelf
                      products={data?.vtex.productsByIdentifier}
                      ProductSummary={ProductSummary}
                      shelfName="UltraMalva"
                      isStaticShelf
                    />
                  </Box>
                  <Text as="p" sx={styles.shelfContainer.description}>
                    Clique no produto para ver a cor que você escolheu e outras
                    opções de bocas!
                  </Text>
                </>
              )}
            </Box>
          </Box>
        </Flex>
      </Hidden>
    </Box>
  )
}

export default UltraMalvaSection
